import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import StickyNav from "../../components/sticky-nav/sticky-nav"
import Footer from "../../components/footer/footer"
import RichTextContent from "../../components/rich-text-content/rich-text-content"

/* Styles */
import {
  backgroundImage,
  expertContent,
  expertImageWrapper,
  expertPhoto,
  expertContentInner,
  social,
  intro,
} from "./expert.module.css"

import { FaFacebook, FaGlobe, FaLinkedinIn, FaTwitter } from "react-icons/fa"
import { Helmet } from "react-helmet"

class ExpertTemplate extends React.Component {
  render() {
    const desks = get(this.props, "pageContext.desks")
    const image = getImage(this.props.data.file.childImageSharp)
    const bgImage = convertToBgImage(image)

    const expert = get(this.props, "data.contentfulExpert")

    const description = get(
      expert,
      "description.childMarkdownRemark.rawMarkdownBody"
    )

    const pageUrl = `https://www.mkbservicedesk.nl/${linkBuilder.expert(
      expert
    )}`

    const ldJson = {
      "@context": "https://schema.org",
      "@type": "Person",
      name: expert.name,
      url: pageUrl,
    }

    const sameAs = []
    if (expert.linkedinUrl) sameAs.push(expert.linkedinUrl)
    if (expert.twitterUrl) sameAs.push(expert.twitterUrl)
    if (expert.facebookUrl) sameAs.push(expert.facebookUrl)
    if (expert.websiteUrl) sameAs.push(expert.websiteUrl)
    if (sameAs.length) ldJson.sameAs = sameAs
    if (expert.image?.gatsbyImageData) ldJson.image = expert.image.gatsbyImageData.images.fallback.src
    if (expert.description) ldJson.description = expert.description

    return (
      <Layout location={this.props.location}>
        <Seo title={expert.name} description={description} />
        <SocialMeta
          title={expert.name}
          description={description}
          url={linkBuilder.expert(expert)}
          image={expert.image && expert.image.gatsbyImageData && expert.image.gatsbyImageData.images.fallback.src}
        />
        <Helmet>
          <link rel="canonical" href={pageUrl} />
          <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        </Helmet>
        <header className="header">
          <StickyNav desks={desks} />
          <div className={backgroundImage}>
            <BackgroundImage
              className={backgroundImage}
              {...bgImage}
            ></BackgroundImage>
          </div>
        </header>
        <main className="main expert">
          <div className="container">
            <section className={expertContent}>
              <div className={expertImageWrapper}>
                {expert.image && (
                  <GatsbyImage
                    className={expertPhoto}
                    image={expert.image.gatsbyImageData}
                    alt={expert.name}
                  />
                )}
              </div>

              <div className={expertContentInner}>
                <h1>{expert.name}</h1>

                {expert.linkedinUrl && (
                  <a
                    className={social}
                    href={expert.linkedinUrl}
                    rel="noopener noreferrer"
                    alt={`LinkedIn profiel ${expert.name}`}
                  >
                    <FaLinkedinIn />
                  </a>
                )}
                {expert.twitterUrl && (
                  <a
                    className={social}
                    href={expert.twitterUrl}
                    rel="noopener noreferrer"
                    alt={`Twitter profiel ${expert.name}`}
                  >
                    <FaTwitter />
                  </a>
                )}
                {expert.facebookUrl && (
                  <a
                    className={social}
                    href={expert.facebookUrl}
                    rel="noopener noreferrer"
                    alt={`Facebook profiel ${expert.name}`}
                  >
                    <FaFacebook />
                  </a>
                )}
                {expert.websiteUrl && (
                  <a
                    className={social}
                    href={expert.websiteUrl}
                    rel="noopener noreferrer"
                    alt={`Persoonlijke website ${expert.name}`}
                  >
                    <FaGlobe />
                  </a>
                )}

                {description && <p className={intro}>{description}</p>}

                {expert.body && (
                  <div className="richtext">
                    <RichTextContent
                      content={expert.body}
                      addListMarker={true}
                      displayPagePath={linkBuilder.expert(expert)}
                    />
                  </div>
                )}
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default ExpertTemplate

export const pageQuery = graphql`
  query ExpertBySlug($slug: String!) {
    file(relativePath: { eq: "auteur-typen-toetsenbord.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1180)
      }
    }

    site {
      siteMetadata {
        title
      }
    }

    contentfulExpert(slug: { eq: $slug }) {
      slug
      name
      linkedinUrl
      twitterUrl
      facebookUrl
      websiteUrl
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      image {
        gatsbyImageData(layout: FIXED, width: 160, height: 160)
      }
      body {
        raw
      }
    }
  }
`
